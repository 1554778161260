import React, { Component } from "react";
import { Link } from "react-router-dom";
import reportVisitService from "../../reporting-visit/report-visit.service";
import Loader from "../loader.component";

import generatorService from "../../services/generator.service";

export default class PasswordGenerator extends Component {
  constructor(props) {
    super(props);
    this.generatePassword = this.generatePassword.bind(this);
    this.onUpperCaseChange = this.onUpperCaseChange.bind(this);
    this.onLowerCaseChange = this.onLowerCaseChange.bind(this);
    this.onNumberCaseChange = this.onNumberCaseChange.bind(this);
    this.onSpecialCaseChange = this.onSpecialCaseChange.bind(this);
    this.onLengthChange = this.onLengthChange.bind(this);
    this.onFocusInput = this.onFocusInput.bind(this);

    this.state = {
      isUpperCase: false,
      isLowerCase: false,
      isNumberCase: false,
      isSpecialCase: false,
      length: 0,
      errorMessage: null,
      loading: false,
      password: "",
    };
  }
  onFocusInput(event) {
    event.target.select();
  }

  onUpperCaseChange() {
    this.setState({
      isUpperCase: !this.state.isUpperCase,
    });
  }

  onLowerCaseChange() {
    this.setState({
      isLowerCase: !this.state.isLowerCase,
    });
  }

  onNumberCaseChange() {
    this.setState({
      isNumberCase: !this.state.isNumberCase,
    });
  }

  onSpecialCaseChange() {
    this.setState({
      isSpecialCase: !this.state.isSpecialCase,
    });
  }

  onLengthChange(event) {
    this.setState({
      length: event.target.valueAsNumber,
    });
  }

  generatePassword() {
    this.setState({
      loading: true,
    });

    var data = {
      upperCase: this.state.isUpperCase,
      lowerCase: this.state.isLowerCase,
      number: this.state.isNumberCase,
      specialCase: this.state.isSpecialCase,
      quantityCase: this.state.length,
    };

    console.log(data);
    generatorService
      .passwordGenerator(data)
      .then((response) => {
        console.log(response);
        if (response.data.status === "SUCCESS") {
          this.setState({
            password: response.data.password,
            errorMessage: "",
          });
        } else {
          this.setState({
            password: "",
            errorMessage: response.data.message,
          });
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    document.title =
      "Password Generator | Dev tools - Free online tools for developers";

    reportVisitService.report(window.location.pathname, document.title);
  }

  render() {
    return (
      <div>
        <header>
          <div className="title">
            <Link to={`/`}>
              <img
                title="Back"
                src={require("../../icons/left.svg").default}
                height="50"
                width="50"
                alt="Back"
              />
            </Link>
            <h1>Password Generator</h1>
          </div>
        </header>
        <div className="container mt-3">
          <div className="grid">
            {this.state.loading ? <Loader /> : null}

            <div className="password_generator_div_checkboxes">
              <button
                className={
                  this.state.isUpperCase
                    ? "password_generator_checkbox_checked"
                    : "password_generator_checkbox_unchecked"
                }
                onClick={this.onUpperCaseChange}
              />{" "}
              <label className="password_generator_label_checkbox">
                Upper case
              </label>
              <br />
              <br />
              <button
                className={
                  this.state.isLowerCase
                    ? "password_generator_checkbox_checked"
                    : "password_generator_checkbox_unchecked"
                }
                onClick={this.onLowerCaseChange}
              />{" "}
              <label className="password_generator_label_checkbox">
                Lower case
              </label>
              <br />
              <br />
              <button
                className={
                  this.state.isNumberCase
                    ? "password_generator_checkbox_checked"
                    : "password_generator_checkbox_unchecked"
                }
                onClick={this.onNumberCaseChange}
              />{" "}
              <label className="password_generator_label_checkbox">
                Numbers
              </label>
              <br />
              <br />
              <button
                className={
                  this.state.isSpecialCase
                    ? "password_generator_checkbox_checked"
                    : "password_generator_checkbox_unchecked"
                }
                onClick={this.onSpecialCaseChange}
              />{" "}
              <label className="password_generator_label_checkbox">
                Special case
              </label>
            </div>
            <br />
            <br />

            <label className="password_generator_label">
              Length of password
            </label>
            <div className="password_generator_div_textbox">
              <input
                type="number"
                onFocus={this.onFocusInput}
                className="password_generator_length"
                id="length"
                value={this.state.length}
                onChange={this.onLengthChange}
              />{" "}
              <br />
            </div>

            <br />
            <br />

            <div className="password_generator_div_button">
              <button
                className="password_generator_button_generate"
                onClick={this.generatePassword}
              >
                Generate
              </button>
            </div>
            <br />

            {this.state.errorMessage && this.state.errorMessage.length > 0 ? (
              <div>
                <label className="password_generator_error_label">
                  Warning
                </label>
                <label className="password_generator_error_message">
                  {this.state.errorMessage}
                </label>
              </div>
            ) : (
              <label className="password_generator_password_label">
                {this.state.password}
              </label>
            )}
          </div>
        </div>
      </div>
    );
  }
}

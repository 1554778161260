import http from "../http-common";

class EncryptorService {
  encrypt(data) {
    return http.post("/encryptors/text-encryptor/encrypt", data);
  }

  decrypt(data) {
    return http.post("/encryptors/text-encryptor/decrypt", data);
  }
}

export default new EncryptorService();

import React, { Component } from "react";
import { Link } from "react-router-dom";
import reportVisitService from "../../reporting-visit/report-visit.service";

import generatorService from "../../services/generator.service";
import Loader from "../loader.component";

export default class BarcodeGenerator extends Component {
  constructor(props) {
    super(props);
    this.generateBarcode = this.generateBarcode.bind(this);
    this.onBarcodeTextChange = this.onBarcodeTextChange.bind(this);
    this.onHeightChange = this.onHeightChange.bind(this);
    this.onWidthChange = this.onWidthChange.bind(this);
    this.downloadBarcode = this.downloadBarcode.bind(this);
    this.onResolutionChange = this.onResolutionChange.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.onBarcodeTypeChange = this.onBarcodeTypeChange.bind(this);

    this.state = {
      barcodeType: "QRCODE",
      barcodeText: "",
      resolution: 1000,
      width: 600,
      height: 600,
      barcodeImage: null,
      errorMessage: null,
      loading: false,
    };
  }

  onBarcodeTypeChange(event) {
    this.setState({
      barcodeType: event.target.value,
      barcodeImage: null,
    });
  }

  onBarcodeTextChange(event) {
    this.setState({
      barcodeText: event.target.value,
      barcodeImage: null,
    });
  }

  onHeightChange(event) {
    this.setState({
      height: event.target.valueAsNumber,
      barcodeImage: null,
    });
  }

  onResolutionChange(event) {
    this.setState({
      resolution: event.target.valueAsNumber,
      barcodeImage: null,
    });
  }

  onWidthChange(event) {
    this.setState({
      width: event.target.valueAsNumber,
      barcodeImage: null,
    });
  }

  generateBarcode() {
    this.setState({
      loading: true,
    });

    var data = {
      text: this.state.barcodeText,
      height: this.state.height,
      width: this.state.width,
    };
    var data1 = {
      text: this.state.barcodeText,
      resolution: this.state.resolution,
    };

    switch (this.state.barcodeType) {
      case "QRCODE":
        generatorService
          .barcodeGeneratorQRCode(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              this.setState({
                barcodeImage: response.data.content,
                errorMessage: null,
              });
            } else {
              this.setState({
                barcodeImage: null,
                errorMessage: response.data.errorMessage,
              });
            }
            this.setState({
              loading: false,
            });
            setTimeout(this.scrollToBottom, 50);
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
          });
        break;
      case "EAN8":
        generatorService
          .barcodeGeneratorEAN8(data1)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              this.setState({
                barcodeImage: response.data.content,
                errorMessage: null,
              });
            } else {
              this.setState({
                barcodeImage: null,
                errorMessage: response.data.errorMessage,
              });
            }
            this.setState({
              loading: false,
            });
            setTimeout(this.scrollToBottom, 50);
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
          });
        break;
      case "EAN13":
        generatorService
          .barcodeGeneratorEAN13(data1)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              this.setState({
                barcodeImage: response.data.content,
                errorMessage: null,
              });
            } else {
              this.setState({
                barcodeImage: null,
                errorMessage: response.data.errorMessage,
              });
            }
            this.setState({
              loading: false,
            });
            setTimeout(this.scrollToBottom, 50);
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
          });
        break;
      case "CODE128":
        generatorService
          .barcodeGeneratorCode128(data1)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              this.setState({
                barcodeImage: response.data.content,
                errorMessage: null,
              });
            } else {
              this.setState({
                barcodeImage: null,
                errorMessage: response.data.errorMessage,
              });
            }
            this.setState({
              loading: false,
            });
            setTimeout(this.scrollToBottom, 50);
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
          });
        break;
      case "PDF417":
        generatorService
          .barcodeGeneratorPDF417(data1)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              this.setState({
                barcodeImage: response.data.content,
                errorMessage: null,
              });
            } else {
              this.setState({
                barcodeImage: null,
                errorMessage: response.data.errorMessage,
              });
            }
            this.setState({
              loading: false,
            });
            setTimeout(this.scrollToBottom, 50);
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
          });
        break;
      case "UPCA":
        generatorService
          .barcodeGeneratorUPCA(data1)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              this.setState({
                barcodeImage: response.data.content,
                errorMessage: null,
              });
            } else {
              this.setState({
                barcodeImage: null,
                errorMessage: response.data.errorMessage,
              });
            }
            this.setState({
              loading: false,
            });
            setTimeout(this.scrollToBottom, 50);
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
          });
        break;
      case "UPCE":
        generatorService
          .barcodeGeneratorUPCE(data1)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              this.setState({
                barcodeImage: response.data.content,
                errorMessage: null,
              });
            } else {
              this.setState({
                barcodeImage: null,
                errorMessage: response.data.errorMessage,
              });
            }
            this.setState({
              loading: false,
            });
            setTimeout(this.scrollToBottom, 50);
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
          });
        break;
      case "CODE39":
        generatorService
          .barcodeGeneratorCode39(data1)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              this.setState({
                barcodeImage: response.data.content,
                errorMessage: null,
              });
            } else {
              this.setState({
                barcodeImage: null,
                errorMessage: response.data.errorMessage,
              });
            }
            this.setState({
              loading: false,
            });
            setTimeout(this.scrollToBottom, 50);
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
          });
        break;
      case "CODE93":
        generatorService
          .barcodeGeneratorCode93(data)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              this.setState({
                barcodeImage: response.data.content,
                errorMessage: null,
              });
            } else {
              this.setState({
                barcodeImage: null,
                errorMessage: response.data.errorMessage,
              });
            }
            this.setState({
              loading: false,
            });
            setTimeout(this.scrollToBottom, 50);
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
          });
        break;
      default:
        this.setState({
          errorMessage:
            "Please select the type of barcode you want to generate..",
          loading: false,
        });
        break;
    }
  }

  scrollToBottom() {
    const { barcodeImage } = this.refs;
    barcodeImage.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }

  downloadBarcode() {
    var a = document.createElement("a"); //Create <a>
    a.href = "data:image/png;base64," + this.state.barcodeImage; //Image Base64 Goes here
    a.download =
      this.state.barcodeType +
      " - " +
      this.state.barcodeText.substring(0, 100) +
      ".png"; //File name Here
    a.click(); //Downloaded file
  }
  componentDidMount() {
    document.title =
      "Barcode Generator | Dev tools - Free online tools for developers";

    reportVisitService.report(window.location.pathname, document.title);
  }

  render() {
    return (
      <div>
        <header>
          <div className="title">
            <Link to={`/`}>
              <img
                title="Back"
                src={require("../../icons/left.svg").default}
                height="50"
                width="50"
                alt="Back"
              />
            </Link>
            <h1>Barcode Generator</h1>
          </div>
        </header>
        <div className="container mt-3">
          <div className="barcode_generator_grid">
            {this.state.loading ? <Loader /> : null}
            <div className="unit_converter_div_button">
              <select
                value={this.state.barcodeType}
                onChange={this.onBarcodeTypeChange}
                className="unit_converter_select_units"
              >
                <option value="QRCODE">QR Code</option>
                <option value="EAN13">EAN 13</option>
                <option value="EAN8">EAN 8</option>
                <option value="PDF417">PDF 417</option>
                <option value="UPCA">UPCA</option>
                <option value="UPCE">UPC E</option>
                <option value="CODE39">Code 39</option>
                <option value="CODE93">Code 93</option>
                <option value="CODE128">Code 128</option>
              </select>
            </div>

            <br />
            {this.state.barcodeType !== "QRCODE" &&
            this.state.barcodeType !== "CODE93" ? (
              <div>
                <label className="barcode_generator_label">Resolution</label>
                <input
                  className="barcode_generator_small_input"
                  value={this.state.resolution}
                  type="number"
                  onChange={this.onResolutionChange}
                />
              </div>
            ) : (
              <div>
                <label className="barcode_generator_label">Height</label>
                <input
                  className="barcode_generator_small_input"
                  value={this.state.height}
                  type="number"
                  onChange={this.onHeightChange}
                />

                <br />
                <label className="barcode_generator_label">Width</label>
                <input
                  className="barcode_generator_small_input"
                  value={this.state.width}
                  type="number"
                  onChange={this.onWidthChange}
                />
              </div>
            )}

            <br />
            <div>
              <label className="barcode_generator_label">
                Content of barcode
              </label>
              <textarea
                className="barcode_generator_barcode_content"
                placeholder="Type content of barcode"
                type="text"
                value={this.state.barcodeText}
                onChange={this.onBarcodeTextChange}
              />
            </div>
            <br />

            <br />
            <div className="barcode_generator_div_button">
              <button
                className="barcode_generator_button"
                value="Generate"
                onClick={this.generateBarcode}
              >
                Generate
              </button>
            </div>
            <br />

            {this.state.barcodeImage && this.state.barcodeImage.length > 0 ? (
              <div>
                <br />
                <label className="barcode_generator_label">
                  Generated barcode
                </label>
                <img
                  className="barcode_generator_barcode_img"
                  src={`data:image/jpeg;base64,${this.state.barcodeImage}`}
                />
                <br />
                <br />
                <br />
                <br />
                <div
                  ref="barcodeImage"
                  className="barcode_generator_div_button barcode_generator_div_offset"
                >
                  <button
                    className="barcode_generator_button"
                    value="Download"
                    onClick={this.downloadBarcode}
                  >
                    Download
                  </button>
                </div>
              </div>
            ) : (
              <div />
            )}

            {this.state.errorMessage && this.state.errorMessage.length > 0 ? (
              <div>
                <br />
                <label className="barcode_generator_error_label">Warning</label>
                <label className="barcode_generator_error_message">
                  {this.state.errorMessage}
                </label>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
    );
  }
}

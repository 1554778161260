import React, { Component } from "react";
import { Link } from "react-router-dom";
import reportVisitService from "../../reporting-visit/report-visit.service";
import escapersService from "../../services/escapers.service";
import Loader from "../loader.component";

export default class Escapers extends Component {
  constructor(props) {
    super(props);
    this.escapeText = this.escapeText.bind(this);
    this.unescapeText = this.unescapeText.bind(this);
    this.onInputTextChange = this.onInputTextChange.bind(this);

    this.state = {
      errorMessage: null,
      loading: false,
      inputText: "",
      outputText: "",
      escapeType: "",
      title: "",
    };
  }

  onInputTextChange(event) {
    this.setState({
      inputText: event.target.value,
    });
  }

  escapeText() {
    this.setState({
      loading: true,
    });

    var data = {
      text: this.state.inputText,
      type: this.state.escapeType,
    };

    escapersService
      .escape(data)
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          this.setState({
            outputText: response.data.text,
            errorMessage: "",
          });
        } else {
          this.setState({
            outputText: "",
            errorMessage: response.data.message,
          });
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
      });
  }

  unescapeText() {
    this.setState({
      loading: true,
    });

    var data = {
      text: this.state.inputText,
      type: this.state.escapeType,
    };

    escapersService
      .unescape(data)
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          this.setState({
            outputText: response.data.text,
            errorMessage: "",
          });
        } else {
          this.setState({
            outputText: "",
            errorMessage: response.data.message,
          });
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    const pathSplit = window.location.pathname.split("/");
    const param = pathSplit[pathSplit.length - 1];
    const title = param === "javascript" ? "JavaScript" : param.toUpperCase();
    document.title =
      title +
      " Escape/Unescapte | Dev tools - Free online tools for developers";
    this.setState({
      title: title,
      escapeType: param === "javascript" ? "ECMASCRIPT" : param.toUpperCase(),
    });

    reportVisitService.report(window.location.pathname, document.title);
  }

  render() {
    return (
      <div>
        <header>
          <div className="title">
            <Link to={`/`}>
              <img
                title="Back"
                src={require("../../icons/left.svg").default}
                height="50"
                width="50"
                alt="Back"
              />
            </Link>
            <h1>{this.state.title} Escape/Unescape</h1>
          </div>
        </header>
        <div className="container mt-3">
          <div className="grid grid_container">
            {this.state.loading ? <Loader /> : null}

            <label className="text_encryptor_label">
              Input {this.state.title}
            </label>
            <textarea
              className="text_converter_textbox text_converter_textInput"
              value={this.state.inputText}
              placeholder="Insert text"
              onChange={this.onInputTextChange}
            />

            <table className="text_encryptor_table_buttons">
              <tbody>
                <tr>
                  <th>
                    <button
                      className="text_encryptor_button"
                      onClick={this.escapeText}
                    >
                      Escape
                    </button>
                  </th>

                  <th>
                    <button
                      className="text_encryptor_button"
                      onClick={this.unescapeText}
                    >
                      Unescape
                    </button>
                  </th>
                </tr>
              </tbody>
            </table>

            <br />
            <br />

            <label className="text_encryptor_label">
              Result {this.state.title}
            </label>
            <textarea
              readOnly
              className="text_converter_textbox text_converter_textOutput"
              value={this.state.outputText}
            />
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import reportVisitService from "../../reporting-visit/report-visit.service";
import Loader from "../loader.component";

import generatorService from "../../services/generator.service";

export default class GuidGenerator extends Component {
  constructor(props) {
    super(props);
    this.generateGuids = this.generateGuids.bind(this);
    this.onQuantityChange = this.onQuantityChange.bind(this);
    this.onFocusInput = this.onFocusInput.bind(this);

    this.state = {
      quantity: 1,
      errorMessage: null,
      loading: false,
      guids: "",
    };
  }
  onFocusInput(event) {
    event.target.select();
  }

  onQuantityChange(event) {
    this.setState({
      quantity: event.target.valueAsNumber,
    });
  }

  generateGuids() {
    this.setState({
      loading: true,
    });

    var data = {
      quantity: this.state.quantity,
    };

    generatorService
      .guidGenerator(data)
      .then((response) => {
        console.log(response);
        if (response.data.status === "SUCCESS") {
          this.setState({
            guids: response.data.guids.replaceAll("\n", "<br />"),
            errorMessage: "",
          });
        } else {
          this.setState({
            guids: "",
            errorMessage: response.data.message,
          });
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    document.title =
      "Guid Generator | Dev tools - Free online tools for developers";

    reportVisitService.report(window.location.pathname, document.title);
  }

  render() {
    return (
      <div>
        <header>
          <div className="title">
            <Link to={`/`}>
              <img
                title="Back"
                src={require("../../icons/left.svg").default}
                height="50"
                width="50"
                alt="Back"
              />
            </Link>
            <h1>Guid Generator</h1>
          </div>
        </header>
        <div className="container mt-3">
          <div className="grid">
            {this.state.loading ? <Loader /> : null}

            <label className="password_generator_label">Quantity</label>
            <div className="password_generator_div_textbox">
              <input
                type="number"
                onFocus={this.onFocusInput}
                className="password_generator_length"
                id="length"
                value={this.state.quantity}
                onChange={this.onQuantityChange}
              />{" "}
              <br />
            </div>

            <br />
            <br />

            <div className="password_generator_div_button">
              <button
                className="password_generator_button_generate"
                onClick={this.generateGuids}
              >
                Generate
              </button>
            </div>
            <br />

            {this.state.errorMessage && this.state.errorMessage.length > 0 ? (
              <div>
                <label className="password_generator_error_label">
                  Warning
                </label>
                <label className="password_generator_error_message">
                  {this.state.errorMessage}
                </label>
              </div>
            ) : (
              <div
                className="password_generator_password_label"
                dangerouslySetInnerHTML={{
                  __html: this.state.guids,
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import reportVisitService from "../../reporting-visit/report-visit.service";
import Loader from "../loader.component";

import converterService from "../../services/converter.service";

export default class TextConverter extends Component {
  constructor(props) {
    super(props);
    this.convertText = this.convertText.bind(this);
    this.onInputTextChange = this.onInputTextChange.bind(this);
    this.onInputConvertTypeChange = this.onInputConvertTypeChange.bind(this);
    this.onOutputConvertTypeChange = this.onOutputConvertTypeChange.bind(this);

    this.state = {
      errorMessage: null,
      loading: false,
      inputText: "",
      outputText: "",
      outputConvertType: "0",
      inputConvertType: "0",
    };
  }

  onInputConvertTypeChange(event) {
    this.setState({
      inputConvertType: event.target.value,
    });
  }

  onOutputConvertTypeChange(event) {
    this.setState({
      outputConvertType: event.target.value,
    });
  }

  onInputTextChange(event) {
    this.setState({
      inputText: event.target.value,
    });
  }

  convertText() {
    this.setState({
      loading: true,
    });

    var data = {
      text: this.state.inputText,
      outputConvertType: this.state.outputConvertType,
      inputConvertType: this.state.inputConvertType,
    };

    converterService
      .textConverter(data)
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          this.setState({
            outputText: response.data.convertedText,
            errorMessage: "",
          });
        } else {
          this.setState({
            outputText: "",
            errorMessage: response.data.message,
          });
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    document.title =
      "Text Converter | Dev tools - Free online tools for developers";
    reportVisitService.report(window.location.pathname, document.title);
  }

  render() {
    return (
      <div>
        <header>
          <div className="title">
            <Link to={`/`}>
              <img
                title="Back"
                src={require("../../icons/left.svg").default}
                height="50"
                width="50"
                alt="Back"
              />
            </Link>
            <h1>Text Converter</h1>
          </div>
        </header>
        <div className="container mt-3">
          <div className="grid grid_container">
            {this.state.loading ? <Loader /> : null}

            <table className="text_converter_table_select">
              <tbody>
                <tr>
                  <th className="text_converter_table_th_select">
                    <select
                      className="text_converter_select_from"
                      value={this.inputConvertType}
                      onChange={this.onInputConvertTypeChange}
                    >
                      <option value="0">ABC</option>
                      <option value="1">ASCII</option>
                      <option value="2">Binary</option>
                      <option value="3">Hex</option>
                      <option value="4">Octal</option>
                      <option value="5">Morse Code</option>
                    </select>
                  </th>
                  <th className="text_converter_table_th_equal">
                    <p className="text_converter_converter-equals">=&gt;</p>
                  </th>
                  <th>
                    <select
                      className="text_converter_select_to"
                      value={this.outputConvertType}
                      onChange={this.onOutputConvertTypeChange}
                    >
                      <option value="0">ABC</option>
                      <option value="1">ASCII</option>
                      <option value="2">Binary</option>
                      <option value="3">Hex</option>
                      <option value="4">Octal</option>
                      <option value="5">Morse Code</option>
                    </select>
                  </th>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <label className="text_encryptor_label">Input text</label>
            <textarea
              className="text_converter_textbox text_converter_textInput"
              value={this.state.inputText}
              placeholder="Insert text"
              onChange={this.onInputTextChange}
            />

            <div className="text_converter_property_form">
              <button
                className="text_converter_button"
                onClick={this.convertText}
              >
                Convert
              </button>
            </div>
            <br />
            <br />
            <label className="text_encryptor_label">Output text</label>
            <textarea
              readOnly
              className="text_converter_textbox text_converter_textOutput"
              value={this.state.outputText}
            />
          </div>
        </div>
      </div>
    );
  }
}

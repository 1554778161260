import React, { Component } from "react";
import { Link } from "react-router-dom";
import reportVisitService from "../../reporting-visit/report-visit.service";
import parsersService from "../../services/parsers.service";
import Loader from "../loader.component";

export default class UrlParser extends Component {
  constructor(props) {
    super(props);
    this.parseUrl = this.parseUrl.bind(this);
    this.onUrlChange = this.onUrlChange.bind(this);
    this.onFocusInput = this.onFocusInput.bind(this);

    this.state = {
      url: "",
      errorMessage: null,
      loading: false,
      parsedUrl: null,
    };
  }

  onFocusInput(event) {
    event.target.select();
  }

  onUrlChange(event) {
    this.setState({
      url: event.target.value,
    });
  }

  parseUrl() {
    this.setState({
      loading: true,
    });

    var data = {
      url: this.state.url,
    };

    parsersService
      .urlParser(data)
      .then((response) => {
        const responseData = response.data;
        if (responseData.status === "SUCCESS") {
          this.setState({
            parsedUrl: {
              protocol: responseData.protocol,
              query: responseData.query,
              pathname: responseData.pathname,
              auth: responseData.auth,
              host: responseData.host,
              port: responseData.port,
              userInfo: responseData.userInfo,
              password: responseData.password,
              username: responseData.username,
              href: responseData.href,
              hash: responseData.hash,
              subdomain: responseData.subdomain,
              domain: responseData.domain,
              directory: responseData.directory,
              fileName: responseData.fileName,
              topLevelDomain: responseData.topLevelDomain,
              queryList: responseData.queryList,
            },
            errorMessage: "",
          });
        } else {
          this.setState({
            parsedUrl: null,
            errorMessage: response.data.message,
          });
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    document.title =
      "URL Parser | Dev tools - Free online tools for developers";

    reportVisitService.report(window.location.pathname, document.title);
  }

  render() {
    const parsedUrl = this.state.parsedUrl;

    return (
      <div>
        <header>
          <div className="title">
            <Link to={`/`}>
              <img
                title="Back"
                src={require("../../icons/left.svg").default}
                height="50"
                width="50"
                alt="Back"
              />
            </Link>
            <h1>URL Parser</h1>
          </div>
        </header>
        <div className="container mt-3">
          <div className="grid">
            {this.state.loading ? <Loader /> : null}
            <label className="text_encryptor_label">URL</label>
            <input
              type="text"
              className="text_encryptor_small_textbox"
              placeholder="Input url"
              value={this.state.url}
              onChange={this.onUrlChange}
            />

            <div className="random_number_generator_div_button">
              <button
                className="random_number_generator_button"
                onClick={this.parseUrl}
              >
                Parse
              </button>
            </div>
            <br />

            {parsedUrl ? (
              <div className="url_parser_properties_table">
                <label className="url_parser_section_title">URL Parts</label>
                <label className="url_parser_property_title">Protocol:</label>
                <label className="url_parser_property_value">
                  {parsedUrl.protocol}
                </label>
                <hr />
                <label className="url_parser_property_title">User info:</label>
                <label className="url_parser_property_value">
                  {parsedUrl.userInfo}
                </label>
                <hr />
                <label className="url_parser_property_title">Username:</label>
                <label className="url_parser_property_value">
                  {parsedUrl.username}
                </label>
                <hr />
                <label className="url_parser_property_title">Password:</label>
                <label className="url_parser_property_value">
                  {parsedUrl.password}
                </label>
                <hr />
                <label className="url_parser_property_title">Authority:</label>
                <label className="url_parser_property_value">
                  {parsedUrl.auth}
                </label>
                <hr />
                <label className="url_parser_property_title">Host:</label>
                <label className="url_parser_property_value">
                  {parsedUrl.host}
                </label>
                <hr />
                <label className="url_parser_property_title">Subdomain:</label>
                <label className="url_parser_property_value">
                  {parsedUrl.subdomain}
                </label>
                <hr />
                <label className="url_parser_property_title">Domain:</label>
                <label className="url_parser_property_value">
                  {parsedUrl.domain}
                </label>
                <hr />
                <label className="url_parser_property_title">Tld:</label>
                <label className="url_parser_property_value">
                  {parsedUrl.topLevelDomain}
                </label>
                <hr />
                <label className="url_parser_property_title">Port:</label>
                <label className="url_parser_property_value">
                  {parsedUrl.port}
                </label>
                <hr />
                <label className="url_parser_property_title">Directory:</label>
                <label className="url_parser_property_value">
                  {parsedUrl.directory}
                </label>
                <hr />
                <label className="url_parser_property_title">Path:</label>
                <label className="url_parser_property_value">
                  {parsedUrl.pathname}
                </label>
                <hr />
                <label className="url_parser_property_title">File name:</label>
                <label className="url_parser_property_value">
                  {parsedUrl.fileName}
                </label>
                <hr />
                <label className="url_parser_property_title">
                  Query string:
                </label>
                <label className="url_parser_property_value">
                  {parsedUrl.query}
                </label>
                <hr />
                <label className="url_parser_property_title">Hash:</label>
                <label className="url_parser_property_value">
                  {parsedUrl.hash}
                </label>

                {parsedUrl.queryList ? (
                  <div>
                    <br />
                    <br />
                    <label className="url_parser_section_title">
                      Query String
                    </label>
                    {parsedUrl.queryList.map((item, index) => (
                      <div>
                        <label className="url_parser_property_title">
                          {item.name}:
                        </label>
                        <label className="url_parser_property_value">
                          {item.value}
                        </label>
                        {index !== parsedUrl.queryList.length - 1 ? (
                          <hr />
                        ) : null}
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            ) : null}

            {this.state.errorMessage && this.state.errorMessage.length > 0 ? (
              <div>
                <label className="random_number_generator_error_label">
                  Warning
                </label>
                <label className="random_number_generator_error_message">
                  {this.state.errorMessage}
                </label>
              </div>
            ) : (
              <label className="random_number_generator_random_label">
                {this.state.numbers}
              </label>
            )}
          </div>
        </div>
      </div>
    );
  }
}

import http from "../http-common";

class GeneratorService {
  barcodeGeneratorEAN13(data) {
    return http.post("/generators/barcode-generator/ean13", data);
  }

  barcodeGeneratorEAN8(data) {
    return http.post("/generators/barcode-generator/ean8", data);
  }

  barcodeGeneratorCode128(data) {
    return http.post("/generators/barcode-generator/code128", data);
  }

  barcodeGeneratorPDF417(data) {
    return http.post("/generators/barcode-generator/pdf417", data);
  }

  barcodeGeneratorQRCode(data) {
    return http.post("/generators/barcode-generator/QRCode", data);
  }

  barcodeGeneratorUPCA(data) {
    return http.post("/generators/barcode-generator/upcA", data);
  }

  barcodeGeneratorUPCE(data) {
    return http.post("/generators/barcode-generator/upcE", data);
  }

  barcodeGeneratorCode39(data) {
    return http.post("/generators/barcode-generator/code39", data);
  }

  barcodeGeneratorCode93(data) {
    return http.post("/generators/barcode-generator/code93", data);
  }

  passwordGenerator(data) {
    return http.post("/generators/password-generator", data);
  }

  randomNumberGenerator(data) {
    return http.post("/generators/random-number-generator", data);
  }

  guidGenerator(data) {
    return http.post("/generators/guid-generator", data);
  }

  hashGenerator(data) {
    return http.post("/generators/hash-generator", data);
  }

  hmacGenerator(data) {
    return http.post("/generators/hmac-generator", data);
  }
}

export default new GeneratorService();

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Masonry from "react-masonry-css";

import converterService from "../../services/converter.service";
import reportVisitService from "../../reporting-visit/report-visit.service";
import Loader from "../loader.component";

export default class CaseConverter extends Component {
  constructor(props) {
    super(props);
    this.convertText = this.convertText.bind(this);
    this.onInputTextChange = this.onInputTextChange.bind(this);

    this.state = {
      errorMessage: null,
      loading: false,
      inputText: "",
      outputText: "",
      convertTypeButtons: [],
    };
  }

  onInputTextChange(event) {
    this.setState({
      inputText: event.target.value,
    });
  }

  convertText(convertType) {
    this.setState({
      loading: true,
    });

    var data = {
      text: this.state.inputText,
      convertType: convertType,
    };

    converterService
      .caseConverter(data)
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          this.setState({
            outputText: response.data.convertedText,
            errorMessage: "",
          });
        } else {
          this.setState({
            outputText: "",
            errorMessage: response.data.message,
          });
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    document.title =
      "Case Converter | Dev tools - Free online tools for developers";

    const convertTypeButtons = [];
    convertTypeButtons.push({
      type: "LOWER_CASE",
      title: "lower case",
    });
    convertTypeButtons.push({
      type: "UPPER_CASE",
      title: "UPPER CASE",
    });
    convertTypeButtons.push({
      type: "CAMEL_CASE",
      title: "camelCase",
    });
    convertTypeButtons.push({
      type: "CAPITAL_CASE",
      title: "Capital Case",
    });
    convertTypeButtons.push({
      type: "CONSTANT_CASE",
      title: "CONSTANT_CASE",
    });
    convertTypeButtons.push({
      type: "HEADER_CASE",
      title: "Header-Case",
    });
    convertTypeButtons.push({
      type: "DOT_CASE",
      title: "dot.case",
    });
    convertTypeButtons.push({
      type: "PARAM_CASE",
      title: "param-case",
    });
    convertTypeButtons.push({
      type: "PASCAL_CASE",
      title: "PascalCase",
    });
    convertTypeButtons.push({
      type: "PATH_CASE",
      title: "path/case",
    });
    convertTypeButtons.push({
      type: "SENTENCE_CASE",
      title: "Sentence case.",
    });
    convertTypeButtons.push({
      type: "SNAKE_CASE",
      title: "snake_case",
    });
    convertTypeButtons.push({
      type: "SWAP_CASE",
      title: "sWAP cASE",
    });
    convertTypeButtons.push({
      type: "TITLE_CASE",
      title: "Title Case",
    });

    this.setState({
      convertTypeButtons: convertTypeButtons,
    });

    reportVisitService.report(window.location.pathname, document.title);
  }

  render() {
    const breakpointCols = {
      default: 4,
      1400: 3,
      1000: 2,
      500: 1,
    };

    const convertTypeButtons = this.state.convertTypeButtons;

    return (
      <div>
        <header>
          <div className="title">
            <Link to={`/`}>
              <img
                title="Back"
                src={require("../../icons/left.svg").default}
                height="50"
                width="50"
                alt="Back"
              />
            </Link>
            <h1>Case Converter</h1>
          </div>
        </header>
        <div className="container mt-3">
          <div className="grid grid_container">
            {this.state.loading ? <Loader /> : null}
            <label className="text_encryptor_label">Input text</label>
            <textarea
              className="text_converter_textbox text_converter_textInput"
              value={this.state.inputText}
              placeholder="Insert text"
              onChange={this.onInputTextChange}
            />
            <div className="text_converter_property_form">
              <Masonry
                breakpointCols={breakpointCols}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {convertTypeButtons.map((item) => (
                  <button
                    key={item.type}
                    className="case_converter_button"
                    onClick={() => this.convertText(item.type)}
                  >
                    {item.title}
                  </button>
                ))}
              </Masonry>
            </div>
            <br />
            <label className="text_encryptor_label">Output text</label>
            <textarea
              className="text_converter_textbox text_converter_textOutput"
              value={this.state.outputText}
              readOnly
            />
          </div>
        </div>
      </div>
    );
  }
}

import http from "./http-common-reporting";
import { v4 as uuidv4 } from "uuid";

let visitorId;

class ReportVisitService {
  report(path, title) {
    if (!visitorId) {
      visitorId = uuidv4();
    }

    const data = {
      id: "a3eed53b-ea23-4f18-90d8-763e1c77192d",
      path: path,
      pageName: title,
      visitorId: visitorId,
    };
    http.post("/", data);
  }
}

export default new ReportVisitService();

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Masonry from "react-masonry-css";

import imgAndroid from "../icons/android.png";
import imgAbout from "../icons/about.svg";
import imgIcon from "../icons/dev_tools_header.png";

export default class MainPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      converters: [],
      encoders: [],
      encryptors: [],
      escapers: [],
      generators: [],
      parsers: [],
      strippers: [],
    };
  }

  componentDidMount() {
    var converters = [];
    var encoders = [];
    var encryptors = [];
    var escapers = [];
    var generators = [];
    var parsers = [];
    var strippers = [];

    converters.push({
      title: "Case Converter",
      path: "converters/case",
    });

    converters.push({
      title: "Text Converter",
      path: "converters/text",
    });

    converters.push({
      title: "Unit Converter",
      path: "converters/unit",
    });

    encoders.push({
      title: "Base 32 Encoder/Decoder",
      path: "encoders/base32",
    });

    encoders.push({
      title: "Base 64 Encoder/Decoder",
      path: "encoders/base64",
    });

    encoders.push({
      title: "URL Encoder/Decoder",
      path: "encoders/url",
    });

    encryptors.push({
      title: "Text Encryptor",
      path: "encryptors/text",
    });
    escapers.push({
      title: "HTML Escape/Unescape",
      path: "escapers/html",
    });

    escapers.push({
      title: "XML Escape/Unescape",
      path: "escapers/xml",
    });

    escapers.push({
      title: "JSON Escape/Unescape",
      path: "escapers/json",
    });

    escapers.push({
      title: "JAVA Escape/Unescape",
      path: "escapers/java",
    });

    escapers.push({
      title: "JavaScript Escape/Unescape",
      path: "escapers/javascript",
    });

    generators.push({
      title: "Barcode Generator",
      path: "generators/barcode",
    });

    generators.push({
      title: "Guid Generator",
      path: "generators/guid",
    });

    generators.push({
      title: "Hash Generator",
      path: "generators/hash",
    });

    generators.push({
      title: "Hmac Generator",
      path: "generators/hmac",
    });

    generators.push({
      title: "Password Generator",
      path: "generators/password",
    });

    generators.push({
      title: "Random Number Generator",
      path: "generators/random-number",
    });

    parsers.push({
      title: "Url Parser",
      path: "parsers/url",
    });

    strippers.push({
      title: "Html Stripper",
      path: "strippers/html",
    });

    this.setState({
      converters: converters,
      encoders: encoders,
      encryptors: encryptors,
      escapers: escapers,
      generators: generators,
      parsers: parsers,
      strippers: strippers,
    });

    document.title = "Dev Tools | Free online tools for developers";
  }

  render() {
    // Data
    const converters = this.state.converters;
    const encoders = this.state.encoders;
    const encryptors = this.state.encryptors;
    const escapers = this.state.escapers;
    const generators = this.state.generators;
    const parsers = this.state.parsers;
    const strippers = this.state.strippers;

    // UI changes
    const breakpointCols = {
      default: 3,
      1400: 2,
      1000: 1,
    };

    return (
      <div>
        <div>
          <header className={this.state.header_class}>
            <div className="title">
              <img src={imgIcon} alt="Dev tools" height="50" width="50" />
              <h1>Dev Tools</h1>
            </div>

            <nav>
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.davidsimak.dev/projects/dev-tools"
                  >
                    <img
                      title="About app"
                      src={imgAbout}
                      alt="About"
                      height="40"
                      width="40"
                    />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://play.google.com/store/apps/details?id=cz.david_simak.chemistry"
                  >
                    <img
                      title="Android App"
                      src={imgAndroid}
                      alt="Android"
                      height="40"
                      width="40"
                    />
                  </a>
                </li>
              </ul>
            </nav>
          </header>

          <div className="container mt-3">
            <div className="grid">
              <label className="tool-section-title">Converters</label>
              <Masonry
                breakpointCols={breakpointCols}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {converters.map((item) => (
                  <Link
                    key={item.path}
                    className="row_item"
                    to={"/" + item.path}
                  >
                    <div className="row_item_div">
                      <label className="row_item_title">{item.title}</label>
                    </div>
                  </Link>
                ))}
              </Masonry>
              <br />
              <label className="tool-section-title">Encoders</label>
              <Masonry
                breakpointCols={breakpointCols}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {encoders.map((item) => (
                  <Link
                    key={item.path}
                    className="row_item"
                    to={"/" + item.path}
                  >
                    <div className="row_item_div">
                      <label className="row_item_title">{item.title}</label>
                    </div>
                  </Link>
                ))}
              </Masonry>
              <br />
              <label className="tool-section-title">Encryptors</label>
              <Masonry
                breakpointCols={breakpointCols}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {encryptors.map((item) => (
                  <Link
                    key={item.path}
                    className="row_item"
                    to={"/" + item.path}
                  >
                    <div className="row_item_div">
                      <label className="row_item_title">{item.title}</label>
                    </div>
                  </Link>
                ))}
              </Masonry>
              <br />
              <label className="tool-section-title">Escapers</label>
              <Masonry
                breakpointCols={breakpointCols}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {escapers.map((item) => (
                  <Link
                    key={item.path}
                    className="row_item"
                    to={"/" + item.path}
                  >
                    <div className="row_item_div">
                      <label className="row_item_title">{item.title}</label>
                    </div>
                  </Link>
                ))}
              </Masonry>
              <br />
              <label className="tool-section-title">Generators</label>
              <Masonry
                breakpointCols={breakpointCols}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {generators.map((item) => (
                  <Link
                    key={item.path}
                    className="row_item"
                    to={"/" + item.path}
                  >
                    <div className="row_item_div">
                      <label className="row_item_title">{item.title}</label>
                    </div>
                  </Link>
                ))}
              </Masonry>
              <br />
              <label className="tool-section-title">Parsers</label>
              <Masonry
                breakpointCols={breakpointCols}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {parsers.map((item) => (
                  <Link
                    key={item.path}
                    className="row_item"
                    to={"/" + item.path}
                  >
                    <div className="row_item_div">
                      <label className="row_item_title">{item.title}</label>
                    </div>
                  </Link>
                ))}
              </Masonry>
              <br />
              <label className="tool-section-title">Strippers</label>
              <Masonry
                breakpointCols={breakpointCols}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {strippers.map((item) => (
                  <Link
                    key={item.path}
                    className="row_item"
                    to={"/" + item.path}
                  >
                    <div className="row_item_div">
                      <label className="row_item_title">{item.title}</label>
                    </div>
                  </Link>
                ))}
              </Masonry>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

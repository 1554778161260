import http from "../http-common";

class EscapersService {
  escape(data) {
    return http.post("/escape-unescape-tools/escape", data);
  }

  unescape(data) {
    return http.post("/escape-unescape-tools/unescape", data);
  }
}

export default new EscapersService();

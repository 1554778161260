import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import ResizeObserver from "rc-resize-observer";
import "./css/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MainPage from "./components/main-page.component";
import TextConverter from "./components/converters/text-converter.component";
import UnitConverter from "./components/converters/unit-converter.component";
import TextEncryptor from "./components/encryptors/text-encryptor.component";
import BarcodeGenerator from "./components/generators/barcode-generator.component";
import PasswordGenerator from "./components/generators/password-generator.component";
import RandomNumberGenerator from "./components/generators/random-number-generator.component";
import Escapers from "./components/escapers/escaper.component";
import UrlParser from "./components/parsers/url-parser.component";
import HtmlStripper from "./components/strippers/html-stripper.component";
import Encoders from "./components/encoders/encoders.component";
import GuidGenerator from "./components/generators/guid-generator.component copy";
import CaseConverter from "./components/converters/case-converter.component";
import HashGenerator from "./components/generators/hash-generator.component";
import HmacGenerator from "./components/generators/hmac-generator.component";
import Footer from "./components/footer.component";
import reportVisitService from "./reporting-visit/report-visit.service";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footerClassName: "footer footer_fixed",
      oldHeightOfGrid: 0,
    };

    this.updateFooterPosition = this.updateFooterPosition.bind(this);
  }

  componentDidMount() {
    reportVisitService.report(window.location.pathname, document.title);

    this.updateFooterPosition();
    window.addEventListener("resize", this.updateFooterPosition);

    switch (window.location.host) {
      case "password-generator.davidsimak.dev":
        window.location.href =
          "https://dev-tools.davidsimak.dev/generators/password";
        break;
      case "random-number-generator.davidsimak.dev":
        window.location.href =
          "https://dev-tools.davidsimak.dev/generators/random-number";
        break;
      case "barcode-generator.davidsimak.dev":
        window.location.href =
          "https://dev-tools.davidsimak.dev/generators/barcode";
        break;
      case "text-encryptor.davidsimak.dev":
        window.location.href =
          "https://dev-tools.davidsimak.dev/encryptors/text";
        break;
      case "unit-converter.davidsimak.dev":
        window.location.href =
          "https://dev-tools.davidsimak.dev/converters/unit";
        break;
      case "text-converter.davidsimak.dev":
        window.location.href =
          "https://dev-tools.davidsimak.dev/converters/text";
        break;
    }
  }

  updateFooterPosition() {
    if (
      this.state.oldHeightOfGrid !==
      document.getElementById("page_content_wrapper").clientHeight
    ) {
      const heightOfGrid = document.getElementById(
        "page_content_wrapper"
      ).clientHeight;
      const heightOfWindow = window.innerHeight;
      var newFooterClassName = "footer footer_";
      if (heightOfWindow < heightOfGrid + 80) {
        newFooterClassName += "bottom";
      } else {
        newFooterClassName += "fixed";
      }

      this.setState({
        oldHeightOfGrid: heightOfGrid,
        footerClassName: newFooterClassName,
      });
    }
  }

  componentDidUpdate() {
    reportVisitService.report(window.location.pathname, document.title);

    this.updateFooterPosition();
  }

  render() {
    return (
      <div>
        <ResizeObserver onResize={this.updateFooterPosition}>
          <div id="page_content_wrapper">
            <Routes>
              <Route exact path="/" element={<MainPage />} />
              {/* Converters*/}
              <Route
                exact
                path="/converters/text"
                element={<TextConverter />}
              />
              <Route
                exact
                path="/converters/unit"
                element={<UnitConverter />}
              />
              <Route
                exact
                path="/converters/case"
                element={<CaseConverter />}
              />

              {/* Encoders*/}
              <Route path="/encoders/:type" element={<Encoders />} />

              {/* Encryptors*/}
              <Route
                exact
                path="/encryptors/text"
                element={<TextEncryptor />}
              />

              {/* Escapers*/}
              <Route path="/escapers/:type" element={<Escapers />} />

              {/* Generators*/}
              <Route
                exact
                path="/generators/barcode"
                element={<BarcodeGenerator />}
              />
              <Route
                exact
                path="/generators/password"
                element={<PasswordGenerator />}
              />
              <Route
                exact
                path="/generators/random-number"
                element={<RandomNumberGenerator />}
              />
              <Route
                exact
                path="/generators/guid"
                element={<GuidGenerator />}
              />
              <Route
                exact
                path="/generators/hash"
                element={<HashGenerator />}
              />
              <Route
                exact
                path="/generators/hmac"
                element={<HmacGenerator />}
              />

              {/* Parsers*/}
              <Route exact path="/parsers/url" element={<UrlParser />} />
              {/* Strippers*/}
              <Route exact path="/strippers/html" element={<HtmlStripper />} />
            </Routes>
          </div>
        </ResizeObserver>
        <Footer customClass={this.state.footerClassName} />
      </div>
    );
  }
}

export default App;

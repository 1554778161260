import React, { Component } from "react";

import "../css/loader.css"

export default class Loader extends Component {
  render() {
    const loaderText = this.props.customText
      ? this.props.customText
      : "Loading ...";
    return (
      <div className="loader-background">
        <div className="loader"></div>
      </div>
    );
  }
}

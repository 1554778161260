import React, { Component } from "react";
import { Link } from "react-router-dom";
import reportVisitService from "../../reporting-visit/report-visit.service";
import generatorService from "../../services/generator.service";
import Loader from "../loader.component";

export default class HmacGenerator extends Component {
  constructor(props) {
    super(props);
    this.hashText = this.hashText.bind(this);
    this.onInputTextChange = this.onInputTextChange.bind(this);
    this.onSecretKeyChange = this.onSecretKeyChange.bind(this);
    this.onAlgorithmTypeChange = this.onAlgorithmTypeChange.bind(this);

    this.state = {
      inputText: "",
      secretKey: "",
      outputText: "",
      errorMessage: null,
      loading: false,
      algorithmType: "HmacSHA256",
    };
  }

  onInputTextChange(event) {
    this.setState({
      inputText: event.target.value,
    });
  }
  onSecretKeyChange(event) {
    this.setState({
      secretKey: event.target.value,
    });
  }

  onAlgorithmTypeChange(event) {
    this.setState({
      algorithmType: event.target.value,
    });
  }

  hashText() {
    this.setState({
      loading: true,
    });

    var data = {
      text: this.state.inputText,
      algorithm: this.state.algorithmType,
      secretKey: this.state.secretKey,
    };

    generatorService
      .hmacGenerator(data)
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          this.setState({
            outputText: response.data.text,
            errorMessage: "",
          });
        } else {
          this.setState({
            errorMessage: response.data.message,
          });
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    document.title =
      "Hmac Generator | Dev tools - Free online tools for developers";

    reportVisitService.report(window.location.pathname, document.title);
  }

  render() {
    return (
      <div>
        <header>
          <div className="title">
            <Link to={`/`}>
              <img
                title="Back"
                src={require("../../icons/left.svg").default}
                height="50"
                width="50"
                alt="Back"
              />
            </Link>
            <h1>Hmac Generator</h1>
          </div>
        </header>
        <div className="container mt-3">
          <div className="grid grid_container">
            {this.state.loading ? <Loader /> : null}
            <div className="unit_converter_div_button">
              <select
                value={this.state.algorithmType}
                onChange={this.onAlgorithmTypeChange}
                className="unit_converter_select_units"
              >
                <option value="HmacMD5">MD5</option>
                <option value="HmacSHA1">SHA1</option>
                <option value="HmacSHA224">SHA224</option>
                <option value="HmacSHA256">SHA256</option>
                <option value="HmacSHA384">SHA384</option>
                <option value="HmacSHA512">SHA512</option>
              </select>
            </div>
            <label className="text_encryptor_label">Input text</label>
            <textarea
              className="text_converter_textbox text_converter_textInput"
              value={this.state.inputText}
              placeholder="Insert text"
              onChange={this.onInputTextChange}
            />

            <div>
              <label className="text_encryptor_label">Secret key</label>
              <input
                className="text_encryptor_small_textbox"
                placeholder="Insert secret key"
                value={this.state.secretKey}
                onChange={this.onSecretKeyChange}
              />
            </div>

            <div className="text_converter_property_form">
              <button className="text_converter_button" onClick={this.hashText}>
                Compute
              </button>
            </div>

            <br />
            <br />
            <label className="text_encryptor_label">Output text</label>

            <textarea
              readOnly
              className="text_converter_textbox text_converter_textOutput"
              value={this.state.outputText}
            />
          </div>

          {this.state.errorMessage && this.state.errorMessage.length > 0 ? (
            <div>
              <br />
              <label className="text_encryptor_error_label">Warning</label>
              <label className="text_encryptor_error_message">
                {this.state.errorMessage}
              </label>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  }
}

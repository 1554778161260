import React, { Component } from "react";
import { Link } from "react-router-dom";
import reportVisitService from "../../reporting-visit/report-visit.service";
import strippersService from "../../services/strippers.service";
import Loader from "../loader.component";

export default class HtmlStripper extends Component {
  constructor(props) {
    super(props);
    this.stripHtml = this.stripHtml.bind(this);
    this.onInputTextChange = this.onInputTextChange.bind(this);

    this.state = {
      errorMessage: null,
      loading: false,
      inputText: "",
      outputText: "",
    };
  }

  onInputTextChange(event) {
    this.setState({
      inputText: event.target.value,
    });
  }

  stripHtml() {
    this.setState({
      loading: true,
    });

    var data = {
      html: this.state.inputText,
    };

    strippersService
      .htmlStripper(data)
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          this.setState({
            outputText: response.data.strippedHtmlText,
            errorMessage: "",
          });
        } else {
          this.setState({
            outputText: "",
            errorMessage: response.data.message,
          });
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    document.title =
      "HTML Stripper | Dev tools - Free online tools for developers";

    reportVisitService.report(window.location.pathname, document.title);
  }

  render() {
    return (
      <div>
        <header>
          <div className="title">
            <Link to={`/`}>
              <img
                title="Back"
                src={require("../../icons/left.svg").default}
                height="50"
                width="50"
                alt="Back"
              />
            </Link>
            <h1>HTML Stripper</h1>
          </div>
        </header>
        <div className="container mt-3">
          <div className="grid grid_container">
            {this.state.loading ? <Loader /> : null}

            <label className="text_encryptor_label">Input HTML</label>
            <textarea
              className="text_converter_textbox text_converter_textInput"
              value={this.state.inputText}
              placeholder="Insert text"
              onChange={this.onInputTextChange}
            />

            <div className="text_converter_property_form">
              <button
                className="text_converter_button"
                onClick={this.stripHtml}
              >
                Strip HTML
              </button>
            </div>
            <br />
            <br />
            <label className="text_encryptor_label">Result</label>
            <textarea
              readOnly
              className="text_converter_textbox text_converter_textOutput"
              value={this.state.outputText}
            />
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import reportVisitService from "../../reporting-visit/report-visit.service";
import encodersService from "../../services/encoders.service";
import Loader from "../loader.component";

export default class Encoders extends Component {
  constructor(props) {
    super(props);
    this.encodeText = this.encodeText.bind(this);
    this.decodeText = this.decodeText.bind(this);
    this.onInputTextChange = this.onInputTextChange.bind(this);

    this.state = {
      errorMessage: null,
      loading: false,
      inputText: "",
      outputText: "",
      title: "",
      encoderType: "",
    };
  }

  onInputTextChange(event) {
    this.setState({
      inputText: event.target.value,
    });
  }

  encodeText() {
    this.setState({
      loading: true,
    });

    if (this.state.encoderType === "url") {
      var urlData = {
        url: this.state.inputText,
      };

      encodersService
        .urlEncoder(urlData)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            this.setState({
              outputText: response.data.url,
              errorMessage: "",
            });
          } else {
            this.setState({
              outputText: "",
              errorMessage: response.data.message,
            });
          }
          this.setState({
            loading: false,
          });
        })
        .catch((e) => {
          this.setState({
            loading: false,
          });
        });
    }

    if (this.state.encoderType === "base32") {
      var baseData = {
        text: this.state.inputText,
      };

      encodersService
        .base32Encoder(baseData)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            this.setState({
              outputText: response.data.text,
              errorMessage: "",
            });
          } else {
            this.setState({
              outputText: "",
              errorMessage: response.data.message,
            });
          }
          this.setState({
            loading: false,
          });
        })
        .catch((e) => {
          this.setState({
            loading: false,
          });
        });
    }

    if (this.state.encoderType === "base64") {
      var baseData = {
        text: this.state.inputText,
      };

      encodersService
        .base64Encoder(baseData)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            this.setState({
              outputText: response.data.text,
              errorMessage: "",
            });
          } else {
            this.setState({
              outputText: "",
              errorMessage: response.data.message,
            });
          }
          this.setState({
            loading: false,
          });
        })
        .catch((e) => {
          this.setState({
            loading: false,
          });
        });
    }
  }

  decodeText() {
    this.setState({
      loading: true,
    });

    if (this.state.encoderType === "url") {
      var urlData = {
        url: this.state.inputText,
      };

      encodersService
        .urlDecoder(urlData)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            this.setState({
              outputText: response.data.url,
              errorMessage: "",
            });
          } else {
            this.setState({
              outputText: "",
              errorMessage: response.data.message,
            });
          }
          this.setState({
            loading: false,
          });
        })
        .catch((e) => {
          this.setState({
            loading: false,
          });
        });
    }

    if (this.state.encoderType === "base32") {
      var baseData = {
        text: this.state.inputText,
      };

      encodersService
        .base32Decoder(baseData)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            this.setState({
              outputText: response.data.text,
              errorMessage: "",
            });
          } else {
            this.setState({
              outputText: "",
              errorMessage: response.data.message,
            });
          }
          this.setState({
            loading: false,
          });
        })
        .catch((e) => {
          this.setState({
            loading: false,
          });
        });
    }

    if (this.state.encoderType === "base64") {
      var baseData = {
        text: this.state.inputText,
      };

      encodersService
        .base64Decoder(baseData)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            this.setState({
              outputText: response.data.text,
              errorMessage: "",
            });
          } else {
            this.setState({
              outputText: "",
              errorMessage: response.data.message,
            });
          }
          this.setState({
            loading: false,
          });
        })
        .catch((e) => {
          this.setState({
            loading: false,
          });
        });
    }
  }

  componentDidMount() {
    const pathSplit = window.location.pathname.split("/");
    const param = pathSplit[pathSplit.length - 1];

    var title = "";

    switch (param) {
      case "base32":
        title = "Base 32";
        break;
      case "base64":
        title = "Base 64";
        break;
      case "url":
        title = "URL";
        break;
    }

    document.title =
      title + " Encoder/Decoder | Dev tools - Free online tools for developers";
    this.setState({
      title: title,
      encoderType: param,
    });

    reportVisitService.report(window.location.pathname, document.title);
  }

  render() {
    return (
      <div>
        <header>
          <div className="title">
            <Link to={`/`}>
              <img
                title="Back"
                src={require("../../icons/left.svg").default}
                height="50"
                width="50"
                alt="Back"
              />
            </Link>
            <h1>{this.state.title} Encoder/Decoder</h1>
          </div>
        </header>
        <div className="container mt-3">
          <div className="grid grid_container">
            {this.state.loading ? <Loader /> : null}

            <label className="text_encryptor_label">Input text</label>
            <textarea
              className="text_converter_textbox text_converter_textInput"
              value={this.state.inputText}
              placeholder="Insert text"
              onChange={this.onInputTextChange}
            />
            <table className="text_encryptor_table_buttons">
              <tbody>
                <tr>
                  <th>
                    <button
                      className="text_encryptor_button"
                      onClick={this.encodeText}
                    >
                      Encode
                    </button>
                  </th>

                  <th>
                    <button
                      className="text_encryptor_button"
                      onClick={this.decodeText}
                    >
                      Decode
                    </button>
                  </th>
                </tr>
              </tbody>
            </table>

            <br />
            <br />
            <label className="text_encryptor_label">Result</label>
            <textarea
              readOnly
              className="text_converter_textbox text_converter_textOutput"
              value={this.state.outputText}
            />
          </div>
        </div>
      </div>
    );
  }
}

import http from "../http-common";

class ConvertersService {
  textConverter(data) {
    return http.post("/converters/text-converter", data);
  }

  unitConverter(data) {
    return http.post("/converters/unit-converter", data);
  }

  caseConverter(data) {
    return http.post("/converters/case-converter", data);
  }
}

export default new ConvertersService();

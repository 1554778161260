import React, { Component } from "react";
import { Link } from "react-router-dom";
import reportVisitService from "../../reporting-visit/report-visit.service";
import Loader from "../loader.component";

import encryptorService from "../../services/encryptor.service";

export default class TextEncryptor extends Component {
  constructor(props) {
    super(props);
    this.encryptText = this.encryptText.bind(this);
    this.decryptText = this.decryptText.bind(this);
    this.onTextPasswordChange = this.onTextPasswordChange.bind(this);
    this.onNumericPasswordChange = this.onNumericPasswordChange.bind(this);
    this.onInputTextChange = this.onInputTextChange.bind(this);
    this.onCipherChange = this.onCipherChange.bind(this);

    this.state = {
      inputText: "",
      password: "",
      errorMessage: null,
      loading: false,
      cipherType: "AES",
      passwordType: "Word",
      outputText: "",
    };
  }

  onInputTextChange(event) {
    this.setState({
      inputText: event.target.value,
    });
  }

  onTextPasswordChange(event) {
    this.setState({
      password: event.target.value,
    });
  }

  onNumericPasswordChange(event) {
    const value = event.target.value;
    if (value.length === 0 || /^\d+$/.test(value)) {
      this.setState({
        password: value,
      });
    }
  }

  encryptText() {
    this.setState({
      loading: true,
    });

    var data = {
      text: this.state.inputText,
      password: this.state.password,
      cipherType: this.state.cipherType,
    };

    encryptorService
      .encrypt(data)
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          this.setState({
            outputText: response.data.text,
            errorMessage: "",
          });
        } else {
          this.setState({
            errorMessage: response.data.message,
          });
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
      });
  }

  onCipherChange(event) {
    const cipherType = event.target.value;
    var passwordType = "none";

    switch (cipherType) {
      case "AES":
      case "WordKey":
      case "LetterShiftByPassword":
        passwordType = "Word";
        break;
      case "LetterShiftSequential":
      case "NumericKey":
      case "LetterShift":
        passwordType = "Number";
        break;
      default:
        passwordType = "none";
        break;
    }

    this.setState({
      cipherType: cipherType,
      password: "",
      passwordType: passwordType,
    });
  }

  decryptText() {
    this.setState({
      loading: true,
    });

    var data = {
      text: this.state.inputText,
      password: this.state.password,
      cipherType: this.state.cipherType,
    };

    encryptorService
      .decrypt(data)
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          this.setState({
            outputText: response.data.text,
            errorMessage: "",
          });
        } else {
          this.setState({
            errorMessage: response.data.message,
          });
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    document.title =
      "Text Encryptor | Dev tools - Free online tools for developers";

    reportVisitService.report(window.location.pathname, document.title);
  }

  render() {
    return (
      <div>
        <header>
          <div className="title">
            <Link to={`/`}>
              <img
                title="Back"
                src={require("../../icons/left.svg").default}
                height="50"
                width="50"
                alt="Back"
              />
            </Link>
            <h1>Text Encryptor</h1>
          </div>
        </header>
        <div className="container mt-3">
          <div className="grid grid_container">
            {this.state.loading ? <Loader /> : null}
            <div className="unit_converter_div_button">
              <select
                value={this.state.cipherType}
                onChange={this.onCipherChange}
                className="unit_converter_select_units"
              >
                <option value="AES">AES</option>
                <option value="FrontAndBack">Front and back</option>
                <option value="LetterShift">Letter shift</option>
                <option value="LetterShiftByPassword">
                  Letter shift by password
                </option>
                <option value="LetterShiftSequential">
                  Letter shift sequential
                </option>
                <option value="NumericKey">Numeric key</option>
                <option value="WordKey">Word key</option>
                <option value="TextSpacing">Text spacing</option>
              </select>
            </div>
            <label className="text_encryptor_label">Input text</label>
            <textarea
              className="text_converter_textbox text_converter_textInput"
              value={this.state.inputText}
              placeholder="Insert text"
              onChange={this.onInputTextChange}
            />

            <br />

            {this.state.passwordType === "Word" ? (
              <div>
                <label className="text_encryptor_label">Password</label>
                <input
                  autoComplete="new-password"
                  type="password"
                  className="text_encryptor_small_textbox"
                  placeholder="Input password"
                  value={this.state.password}
                  onChange={this.onTextPasswordChange}
                />
              </div>
            ) : null}

            {this.state.passwordType === "Number" ? (
              <div>
                <label className="text_encryptor_label">Numeric password</label>
                <input
                  autocomplete="new-password"
                  type="password"
                  className="text_encryptor_small_textbox"
                  placeholder="Input numeric password"
                  value={this.state.password}
                  onChange={this.onNumericPasswordChange}
                ></input>
              </div>
            ) : null}

            <table className="text_encryptor_table_buttons">
              <tbody>
                <tr>
                  <th>
                    <button
                      className="text_encryptor_button"
                      onClick={this.encryptText}
                    >
                      Encrypt
                    </button>
                  </th>

                  <th>
                    <button
                      className="text_encryptor_button"
                      onClick={this.decryptText}
                    >
                      Decrypt
                    </button>
                  </th>
                </tr>
              </tbody>
            </table>
            <br />
            <br />

            <label className="text_encryptor_label">Output text</label>
            <textarea
              readOnly
              className="text_converter_textbox text_converter_textOutput"
              value={this.state.outputText}
            />

            {this.state.errorMessage && this.state.errorMessage.length > 0 ? (
              <div>
                <br />
                <label className="text_encryptor_error_label">Warning</label>
                <label className="text_encryptor_error_message">
                  {this.state.errorMessage}
                </label>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import reportVisitService from "../../reporting-visit/report-visit.service";
import Loader from "../loader.component";

import generatorService from "../../services/generator.service";

export default class RandomNumberGenerator extends Component {
  constructor(props) {
    super(props);
    this.generateNumbers = this.generateNumbers.bind(this);
    this.onMinChange = this.onMinChange.bind(this);
    this.onMaxChange = this.onMaxChange.bind(this);
    this.onQuantityChange = this.onQuantityChange.bind(this);
    this.onCanRepeateChange = this.onCanRepeateChange.bind(this);
    this.onFocusInput = this.onFocusInput.bind(this);

    this.state = {
      canRepeat: false,
      min: 0,
      max: 0,
      quantity: 0,
      errorMessage: null,
      loading: false,
      numbers: "",
    };
  }

  onFocusInput(event) {
    event.target.select();
  }

  onCanRepeateChange() {
    this.setState({
      canRepeat: !this.state.canRepeat,
    });
  }

  onMaxChange(event) {
    this.setState({
      max: event.target.valueAsNumber,
    });
  }

  onMinChange(event) {
    this.setState({
      min: event.target.valueAsNumber,
    });
  }

  onQuantityChange(event) {
    this.setState({
      quantity: event.target.valueAsNumber,
    });
  }

  generateNumbers() {
    this.setState({
      loading: true,
    });

    var data = {
      quantity: this.state.quantity,
      repeat: this.state.canRepeat,
      min: this.state.min,
      max: this.state.max,
    };

    generatorService
      .randomNumberGenerator(data)
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          this.setState({
            numbers: response.data.randomNumbers,
            errorMessage: "",
          });
        } else {
          this.setState({
            numbers: "",
            errorMessage: response.data.message,
          });
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    document.title =
      "Random Number Generator | Dev tools - Free online tools for developers";

    reportVisitService.report(window.location.pathname, document.title);
  }

  render() {
    return (
      <div>
        <header>
          <div className="title">
            <Link to={`/`}>
              <img
                title="Back"
                src={require("../../icons/left.svg").default}
                height="50"
                width="50"
                alt="Back"
              />
            </Link>
            <h1>Ranodm Number Generator</h1>
          </div>
        </header>
        <div className="container mt-3">
          <div className="grid">
            {this.state.loading ? <Loader /> : null}

            <div className="random_number_generator_div_inputs">
              <label className="random_number_generator_label">Min</label>
              <br />
              <input
                type="number"
                onFocus={this.onFocusInput}
                className="random_number_generator_input_box"
                value={this.state.min}
                onChange={this.onMinChange}
              />
              <br />
              <label className="random_number_generator_label">Max</label>
              <br />
              <input
                type="number"
                className="random_number_generator_input_box"
                onFocus={this.onFocusInput}
                value={this.state.max}
                onChange={this.onMaxChange}
              />
              <br />
              <label className="random_number_generator_label">Quantity</label>
              <br />
              <input
                type="number"
                className="random_number_generator_input_box"
                onFocus={this.onFocusInput}
                value={this.state.quantity}
                onChange={this.onQuantityChange}
              />
              <br />
            </div>

            <div className="random_number_generator_div_checkbox">
              <button
                className={
                  this.state.canRepeat
                    ? "random_number_generator_checkbox_checked"
                    : "random_number_generator_checkbox_unchecked"
                }
                onClick={this.onCanRepeateChange}
              />{" "}
              <label className="random_number_generator_label_checkbox">
                Numbers can repeat
              </label>
            </div>
            <br />

            <div className="random_number_generator_div_button">
              <button
                className="random_number_generator_button"
                onClick={this.generateNumbers}
              >
                Generate
              </button>
            </div>
            <br />

            {this.state.errorMessage && this.state.errorMessage.length > 0 ? (
              <div>
                <label className="random_number_generator_error_label">
                  Warning
                </label>
                <label className="random_number_generator_error_message">
                  {this.state.errorMessage}
                </label>
              </div>
            ) : (
              <label className="random_number_generator_random_label">
                {this.state.numbers}
              </label>
            )}
          </div>
        </div>
      </div>
    );
  }
}

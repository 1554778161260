import http from "../http-common";

class EscapersService {
  urlEncoder(data) {
    return http.post("/encoders-decoders/url/encode", data);
  }

  urlDecoder(data) {
    return http.post("/encoders-decoders/url/decode", data);
  }
  base32Encoder(data) {
    return http.post("/encoders-decoders/base32/encode", data);
  }

  base32Decoder(data) {
    return http.post("/encoders-decoders/base32/decode", data);
  }
  base64Encoder(data) {
    return http.post("/encoders-decoders/base64/encode", data);
  }

  base64Decoder(data) {
    return http.post("/encoders-decoders/base64/decode", data);
  }
}

export default new EscapersService();

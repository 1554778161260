import React, { Component } from "react";
import converterService from "../../services/converter.service";
import { Link } from "react-router-dom";
import unitsHelper from "../../helpers/units.helper";
import reportVisitService from "../../reporting-visit/report-visit.service";
import Loader from "../loader.component";

export default class UnitConverer extends Component {
  constructor(props) {
    super(props);
    this.convert = this.convert.bind(this);
    this.onInputValueChange = this.onInputValueChange.bind(this);
    this.onPositionFromChange = this.onPositionFromChange.bind(this);
    this.onPositionToChange = this.onPositionToChange.bind(this);
    this.onConvertTypeChange = this.onConvertTypeChange.bind(this);
    this.renderUnits = this.renderUnits.bind(this);

    this.state = {
      inputValue: 0.0,
      outputValue: 0.0,
      positionFrom: "0",
      positionTo: "0",
      convertType: "m",
      errorMessage: null,
      loading: false,
    };
  }

  onConvertTypeChange(event) {
    this.setState({
      positionFrom: "0",
      positionTo: "0",
      convertType: event.target.value,
    });
  }

  onPositionFromChange(event) {
    this.setState({
      positionFrom: event.target.value,
    });
  }

  onPositionToChange(event) {
    this.setState({
      positionTo: event.target.value,
    });
  }

  onInputValueChange(event) {
    this.setState({
      inputValue: event.target.valueAsNumber,
    });
  }

  convert() {
    this.setState({
      loading: true,
    });

    var data = {
      inputValue: this.state.inputValue,
      positionFrom: this.state.positionFrom,
      positionTo: this.state.positionTo,
      convertType: this.state.convertType,
    };

    converterService
      .unitConverter(data)
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          this.setState({
            outputValue: response.data.result,
            errorMessage: "",
          });
        } else {
          this.setState({
            errorMessage: response.data.message,
          });
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    document.title =
      "Unit Converter | Dev tools - Free online tools for developers";

    reportVisitService.report(window.location.pathname, document.title);
  }

  renderUnits(param, position, fun) {
    switch (param) {
      default:
      case "m":
        return unitsHelper.getLength(position, fun);
      case "l":
        return unitsHelper.getVolume(position, fun);
      case "kg/m³":
        return unitsHelper.getDensity(position, fun);
      case "C":
        return unitsHelper.getCharge(position, fun);
      case "J/K":
        return unitsHelper.getThermalCapacity(position, fun);
      case "K":
        return unitsHelper.getHeat(position, fun);
      case "W/m²":
        return unitsHelper.getIntensityOfRadiation(position, fun);
      case "N":
        return unitsHelper.getForce(position, fun);
      case "Ω":
        return unitsHelper.getResistance(position, fun);
      case "H":
        return unitsHelper.getInductance(position, fun);
      case "F":
        return unitsHelper.getCapacity(position, fun);
      case "Hz":
        return unitsHelper.getFrequency(position, fun);
      case "m/s":
        return unitsHelper.getSpeed(position, fun);
      case "kg":
        return unitsHelper.getWeight(position, fun);
      case "J":
        return unitsHelper.getWork(position, fun);
      case "m/s²":
        return unitsHelper.getAcceleration(position, fun);
      case "rad/s":
        return unitsHelper.getAngularSpeed(position, fun);
      case "Ns":
        return unitsHelper.getImpulsOfForce(position, fun);
      case "Nm":
        return unitsHelper.getMoment(position, fun);
      case "V":
        return unitsHelper.getVoltage(position, fun);
      case "A":
        return unitsHelper.getCurrent(position, fun);
      case "T":
        return unitsHelper.getMagneticInduction(position, fun);
      case "m²":
        return unitsHelper.getContent(position, fun);
      case "s":
        return unitsHelper.getTime(position, fun);
      case "Wb":
        return unitsHelper.getMagneticFlux(position, fun);
      case "Pa":
        return unitsHelper.getPressure(position, fun);
      case "W":
        return unitsHelper.getPerformance(position, fun);
      case "mol":
        return unitsHelper.getSubstanceAmount(position, fun);
      case "g/mol":
        return unitsHelper.getMolarWeight(position, fun);
    }
  }

  render() {
    return (
      <div>
        <header>
          <div className="title">
            <Link to={`/`}>
              <img
                title="Back"
                src={require("../../icons/left.svg").default}
                height="50"
                width="50"
                alt="Back"
              />
            </Link>
            <h1>Unit Converter</h1>
          </div>
        </header>
        <div className="container mt-3"></div>
        <div className="grid grid_container">
          {this.state.loading ? <Loader /> : <div />}

          <div className="unit_converter_div_button">
            <select
              value={this.state.convertType}
              onChange={this.onConvertTypeChange}
              className="unit_converter_select_units"
            >
              <option value="m">Length</option>
              <option value="l">Volume</option>
              <option value="kg/m³">Density</option>
              <option value="C">Charge</option>
              <option value="J/K">Thermal capacity</option>
              <option value="K">Heat</option>
              <option value="W/m²">Intensity of radiation</option>
              <option value="N">Force</option>
              <option value="Ω">Resistance</option>
              <option value="H">Inductance</option>
              <option value="F">Capacity</option>
              <option value="Hz">Frequency</option>
              <option value="m/s">Speed</option>
              <option value="kg">Weight</option>
              <option value="J">Work</option>
              <option value="m/s²">Acceleration</option>
              <option value="rad/s">Angular speed</option>
              <option value="Ns">Impuls of force</option>
              <option value="Nm">Moment</option>
              <option value="V">Voltage</option>
              <option value="A">Current</option>
              <option value="T">Magnetic induction</option>
              <option value="m²2">Content</option>
              <option value="s">Time</option>
              <option value="Wb">Magnetic flux</option>
              <option value="Pa">Pressure</option>
              <option value="W">Performance</option>
              <option value="mol">Substance amount</option>
              <option value="g/mol">Molar weight</option>
            </select>
          </div>

          <table className="unit_converter_table">
            <tbody>
              <tr>
                <th className="unit_converter_table_th_textbox">
                  <input
                    type="number"
                    className="unit_converter_textbox"
                    maxLength="20"
                    value={this.state.inputValue}
                    onChange={this.onInputValueChange}
                  />
                </th>
                <th className="unit_converter_table_th_units">
                  {this.renderUnits(
                    this.state.convertType,
                    this.state.positionFrom,
                    this.onPositionFromChange
                  )}
                </th>
              </tr>
            </tbody>
          </table>

          <div className="unit_converter_div_equals">
            <p>=</p>
          </div>

          <table className="unit_converter_table">
            <tbody>
              <tr>
                <th className="unit_converter_table_th_textbox">
                  <input
                    className="unit_converter_textbox"
                    value={this.state.outputValue}
                    type="number"
                    readOnly
                  />
                </th>
                <th className="unit_converter_table_th_units">
                  {this.renderUnits(
                    this.state.convertType,
                    this.state.positionTo,
                    this.onPositionToChange
                  )}
                </th>
              </tr>
            </tbody>
          </table>

          <div className="unit_converter_div_button">
            <button className="unit_converter_button" onClick={this.convert}>
              Convert
            </button>
          </div>

          {this.state.errorMessage && this.state.errorMessage.length > 0 ? (
            <div>
              <br />
              <label className="unit_converter_error_label">ERROR</label>
              <label className="unit_converter_error_message">
                {this.state.errorMessage}
              </label>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  }
}
